// import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";
import Pagination from "../components/Pagination";
import ProjectsView from "../components/ProjectsView";
import Sectiontitle from "../components/Sectiontitle";
import Loading from "../components/Loading";
import API from "../lib/LibAPI"

function Projects() {
  const [projects, setProjects] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [projectsPerPage] = useState(9);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let mounted = true;
    setIsLoading(true);
    
    API.get("/projects", { cache : true}).then((response) => {
      if (mounted) {
        setProjects(response.data);
        setIsLoading(false);
      }
    });
    return () => (mounted = false);
  }, []);

  const indexOfLastProjects = currentPage * projectsPerPage;
  const indexOfFirstProjects = indexOfLastProjects - projectsPerPage;
  const currentProjects = projects.slice(
    indexOfFirstProjects,
    indexOfLastProjects
  );

  const paginate = (e, pageNumber) => {
    e.preventDefault();
    setCurrentPage(pageNumber);
  };

  return (
    <Layout>
      <Helmet>
        <title>Projects - Byeonlab</title>
        <meta
          name="description"
          content="Byeonlab Projects Page"
        />
      </Helmet>
      <div className="mi-about mi-section mi-padding-top mi-padding-bottom">
        <div className="container">
          <Sectiontitle title="Projects" />
        {isLoading ? (
          <Loading status={true} />
        ) : 
        (
          <>
            {<ProjectsView projects={currentProjects} />}
            {!(projects.length > projectsPerPage) ? null : (
              <Pagination
                className="mt-50"
                itemsPerPage={projectsPerPage}
                totalItems={projects.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            )}
          </>
        )
        }
        </div>
      </div>
    </Layout>
  );
}

export default Projects;
