import React from 'react';
import SyncLoader from "react-spinners/SyncLoader"

function Loading(props){
  return (
    <div
      style={{
          position: 'absolute', left: '50%', top: '50%',
          transform: 'translate(-50%, -50%)',
          //align: 'center'
      }}>
      <SyncLoader color='lightblue' loading={props.status} css={''} size={20} /> 
    </div>
  )
}

export default Loading;
