import FsLightbox from "fslightbox-react";
import React, { useEffect, useState } from "react";
import * as Icon from "react-feather";
import { Helmet } from "react-helmet";
import ProgressiveImage from "react-progressive-image";
import Slider from "react-slick";
import Layout from "../components/Layout";
import Sectiontitle from "../components/Sectiontitle";
import Service from "../components/Service";
import Testimonial from "../components/Testimonial";
import Loading from "../components/Loading";
import API from "../lib/LibAPI"

function About() {
  const [toggler, setToggler] = useState(false);
  const [information, setInformation] = useState("");
  const [services, setServices] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const sliderSettings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 6000,
    pauseOnHover: true,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleToggler = (event) => {
    setToggler(!toggler);
  };

  useEffect(() => {
    setIsLoading(true);
    // axios.get("https://byeonlab.org/api/information").then((response) => {
      API.get("/information", {cache : true}).then((response) => {
      setInformation(response.data);
      setIsLoading(false);
    });
    // axios.get("/api/services").then((response) => {
    //   setServices(response.data);
    // });
    // axios.get("/api/reviews").then((response) => {
    //   setReviews(response.data);
    // });
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>About - Byeonlab</title>
        <meta
          name="description"
          content="Byeonlab About Page"
        />
      </Helmet>
      { isLoading ? (
          <Loading status={true} />
        ) : (
          <div className="mi-about-area mi-section mi-padding-top">
            <div className="container">  
              <Sectiontitle title="About Me" />
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="mi-about-image">
                    <ProgressiveImage
                      src={information.aboutImage}
                      // placeholder="/images/about-image-placeholder.png"
                      //placeholder="/images/blog-image-placeholder.png"
                    >
                      {(src) => (
                        <img
                          src={src}
                          alt="aboutimage"
                          onClick={() => handleToggler(!toggler)}
                        />
                      )}
                    </ProgressiveImage>
                    <span className="mi-about-image-icon">
                      <Icon.ZoomIn />
                    </span>
                    <FsLightbox
                      toggler={toggler}
                      sources={[information.aboutImageLg]}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mi-about-content">
                    <h3>
                      I am <span className="color-theme">{information.name}</span>
                    </h3>
                    <p>
                      I am a DevOps engineer. 
                    </p>
                    <ul>
                      {!information.name ? null : (
                        <li>
                          <b>Name</b> {information.name}
                        </li>
                      )}
                      {!information.age ? null : (
                        <li>
                          <b>Age</b> {information.age} Years
                        </li>
                      )}
                      {!information.phone ? null : (
                        <li>
                          <b>Phone</b> {information.phone}
                        </li>
                      )}
                      {!information.nationality ? null : (
                        <li>
                          <b>Nationality</b> {information.nationality}
                        </li>
                      )}
                      {!information.language ? null : (
                        <li>
                          <b>Languages</b> {information.language}
                        </li>
                      )}
                      {!information.email ? null : (
                        <li>
                          <b>Email</b> {information.email}
                        </li>
                      )}
                      {!information.address ? null : (
                        <li>
                          <b>Address</b> {information.address}
                        </li>
                      )}
                      {!information.freelanceStatus ? null : (
                        <li>
                          <b>Freelance</b> {information.freelanceStatus}
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div> 
            </div>
          </div>
        )
      }

    </Layout>
  );
}

export default About;
