// import Disqus from "disqus-react";
// import Markdown from "markdown-to-jsx";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import Layout from "../components/Layout";
import axios from "axios";
import "react-notion-x/src/styles.css";
import "prismjs/themes/prism-tomorrow.css"; // only needed for code highlighting
import { NotionRenderer, Code, Collection, CollectionRow, Equation } from "react-notion-x";
import 'katex/dist/katex.min.css'
import Loading from "../components/Loading";
import API from "../lib/LibAPI"

function BlogDetails(props) {
  const params = useParams();

  const [response, setResponse] = useState({"block":{}});

  const notionPageId = params.notionPageId;
  const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
      setIsLoading(true);
      let mounted = true;
     // axios.get(`https://byeonlab.org/api/blogDetail`, { params: { pageId : notionPageId} })
      API.get(`/blogDetail?pageId=${notionPageId}`, {cache : true})
        .then((resp) => {
          if (mounted) {
            setResponse(resp.data);
            console.log(resp.data);
            setIsLoading(false);
          }
        });
      //console.log(response)
      return () => (mounted = false);
    }, []);

  return (
    <Layout>
      <Helmet>
        <title>Blog Details - Byeonlab</title>
        <meta
          name="description"
          content="Byeonblab Blog Details Page"
        />
      </Helmet>
      <div className="mi-blog-details mi-section mi-padding-top mi-padding-bottom">
        <div className="container">  
          <Loading status={isLoading} />
          <NotionRenderer
          //blockMap={response}
          recordMap={response}
          //recordMap={response != undefined ? response : {"block":{}}}
          fullPage={true}
          darkMode={true}
          components={{
            pageLink: ({
              href,
              as,
              passHref,
              prefetch,
              replace,
              scroll,
              shallow,
              locale,
              ...props
            }) => (
              <Link
                href={href}
                as={as}
                passHref={passHref}
                prefetch={prefetch}
                replace={replace}
                scroll={scroll}
                shallow={shallow}
                locale={locale}
              >
                <a {...props} />
              </Link>
            ),
            code: Code,
            collection: Collection,
            collectionRow: CollectionRow,
            equation: Equation
          }}
          />
        </div>
      </div>
    </Layout>
  );
}

export default BlogDetails;
