import Mock from "../mock";

const database = {
  services: [
    {
      title: "Web Design",
      icon: 'brush-alt',
      details: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem tenetur ratione quod."
    }
  ],
  reviews: [
    {
      id: 1,
      content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam, aliquid maxime tempora.",
      author: {
        name: 'Burdette Turner',
        designation: 'Web Developer, Abc Company'
      }
    }
  ],
  projects: [
    {
      id: 1,
      title: "Chip-8 Emulator",
      subtitle: "Chip-8 emulator written in C.",
      imageUrl: "/images/chip81.png",
      largeImageUrl: ["/images/chip81.png", "/images/chip82.png"],
      url: 'https://github.com/byeonlab/chip-8'
    }
  ],
  contactInfo: {
    phoneNumbers: ['012-3456-7891'],
    emailAddress: ['byeon@byeonlab.com'],
    address: ""
  }
}


// Mock.onGet("/api/information").reply(config => {
//   const response = database.information;
//   return [200, response];
// });

Mock.onGet("/api/services").reply(config => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply(config => {
  const response = database.reviews;
  return [200, response];
});

// Mock.onGet("/api/skills").reply(config => {
//   const response = database.skills;
//   return [200, response];
// });

// Mock.onGet("/api/projects").reply(config => {
//   const response = database.projects;
//   return [200, response];
// });

// Mock.onGet("/api/experience").reply(config => {
//   const response = database.experience;
//   return [200, response];
// });

// Mock.onGet("/api/blog").reply(config => {
//   const response = database.blogs;
//   return [200, response];
// });

Mock.onGet("/api/contactinfo").reply(config => {
  const response = database.contactInfo;
  return [200, response];
});
