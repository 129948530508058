import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import BlogsView from "../components/BlogsView";
import Layout from "../components/Layout";
import Pagination from "../components/Pagination";
import Sectiontitle from "../components/Sectiontitle";
import SyncLoader from "react-spinners/SyncLoader"
import API from "../lib/LibAPI"

function Blogs() {
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(4);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let mounted = true;
    setIsLoading(true);
    API.get(`/blogs`, {cache : true}).then((response) => {
    // axios.get("https://byeonlab.org/api/blogs").then((response) => {
      if (mounted) {
        setPosts(response.data);
        setIsLoading(false);
        // console.log(response.data)
      }
    });
    return () => (mounted = false);
  }, []);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (e, pageNumber) => {
    e.preventDefault();
    setCurrentPage(pageNumber);
  };

  return (
    <Layout>
      <Helmet>
        <title>Blogs - Byeonlab</title>
        <meta
          name="description"
          content="Byeonlab Blogs Page"
        />
      </Helmet>
      <div className="mi-about mi-section mi-padding-top mi-padding-bottom">
        <div className="container">
          <Sectiontitle title="Recent Blogs" />
          <div
            style={{
                position: 'absolute', left: '50%', top: '100%',
                transform: 'translate(-50%, -50%)',
               // align: 'center'
            }}>
            <SyncLoader color='lightblue' loading={isLoading} css={''} size={20} /> 
          </div>
          {/* <BlogsView blogs={currentPosts} /> */}
          <BlogsView blogs={posts} />
          {/* {!(posts.length > postsPerPage) ? null : (
            <Pagination
              className="mt-50"
              itemsPerPage={postsPerPage}
              totalItems={posts.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          )} */}
        </div>
      </div>
    </Layout>
  );
}

export default Blogs;
